import { useCallback } from 'react';

import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { useLearningAnalytics } from '../../../../analytics/learning';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';

interface NewUserVideoModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

const LEARNING_DEMO_VIDEO_URL = getStaticAssetPath(
  'videos/lp-learning-quick-demo.mp4'
);

export function NewUserVideoModal({
  onConfirm,
  onClose,
}: NewUserVideoModalProps) {
  const analytics = useLearningAnalytics();
  const handleVideoPlayed = () => {
    analytics.trackVideoDemoWatched({});
  };

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[800px] h-auto'
      innerClassName='bg-main-layer'
      onClose={onClose}
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col gap-6'>
        {/* Title and Subtitle */}
        <div className='text-center'>
          <h1 className='text-2xl font-bold'>
            🙌 Welcome to Luna Park Learning! 🙌
          </h1>
          <p className='text-lg mt-2'>
            Watch a quick demo below or click the button to get started!
          </p>
        </div>

        {/* Video Section */}
        <div className='video-container'>
          <video controls className='w-full h-auto' onPlay={handleVideoPlayed}>
            <source src={LEARNING_DEMO_VIDEO_URL} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Get Started Button */}
        <div className='flex justify-center'>
          <button
            type='button'
            onClick={onConfirm}
            className='px-24 py-2 btn btn-primary bg-cyan-500 text-white hover:bg-cyan-600 rounded-lg'
          >
            Get Started!
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

export function useTriggerNewUserVideoModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(() => {
    return triggerModal({
      kind: 'custom',
      containerClassName: 'bg-lp-black-002',
      element: (modalProps) => (
        <NewUserVideoModal
          onClose={modalProps.internalOnCancel}
          onConfirm={() => modalProps.internalOnConfirm()}
        />
      ),
    });
  }, [triggerModal]);
}
