export class LearningAdminStorageManager {
  private static readonly HAS_WATCHED_LEARNING_ADMIN_VIDEO_KEY =
    'hasWatchedLearningAdminVideo';

  static SetHasWatchedLearningAdminVideo(): void {
    localStorage.setItem(this.HAS_WATCHED_LEARNING_ADMIN_VIDEO_KEY, 'true');
  }

  static GetHasWatchedLearningAdminVideo(): boolean {
    const hasVisitedLearningAdmin = localStorage.getItem(
      this.HAS_WATCHED_LEARNING_ADMIN_VIDEO_KEY
    );
    return hasVisitedLearningAdmin === 'true';
  }
}
